<template>
  <div style="background-color: #FFFFFF;">
    <el-tabs v-model="activeName">
      <el-tab-pane label="开具发票" name="invoicing">
        <el-row style="margin:0 20px 20px 20px;">
          <el-col :span="24">
            <div class="il-tools">
              <el-date-picker
                v-model="invoicingDataRange"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :shortcuts="shortcuts"
                @change="handleInvoicingChange"
              >
              </el-date-picker>
            </div>
            <div class="il-list">
              <el-table :data="invoicingData" border :header-cell-style="headerColor">
                <el-table-column prop="orderNum" label="订单编号" width="230" align="center">
                </el-table-column>
                <el-table-column prop="orderPrice" label="实付金额" align="center">
                </el-table-column>
                <el-table-column prop="payType" label="支付方式" align="center">
                  <template #default="scope">
                    <p v-if="scope.row.payType == '00040001'">不限制支付方式</p>
                    <p v-if="scope.row.payType == '00040003'">线下支付</p>
                    <p v-if="scope.row.payType == '00040004'">平台赠送</p>
                    <p v-if="scope.row.payType == '00040005'">微信H5支付</p>
                    <p v-if="scope.row.payType == '00040002'">微信支付</p>
                  </template>
                </el-table-column>
                <el-table-column prop="createTime" label="订单时间" align="center">
                </el-table-column>
                <el-table-column label="操作" width="160" align="center">
                  <template #default="scope">
                    <span class="btn-hover-foucs"
                             @click="handlerIvoice(scope.$index, scope.row)">立即开票
                    </span>
                  </template>
                </el-table-column>
                <template #empty style="width: 100%;">
                  <el-empty :image-size="200"></el-empty>
                </template>
              </el-table>
            </div>
            <div class="il-page">
              <el-pagination
                :hide-on-single-page="true"
                :pager-count="invoicingPage.pagerCount"
                background
                layout="prev, pager, next"
                @current-change="invoicingCurrentChange"
                :total="invoicingPage.total">
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="开票记录" name="invoiced">
        <el-row style="margin:0 20px 20px 20px;">
          <el-col :span="24">
            <div class="il-tools">
              <span style="line-height: 40px;">开票状态：</span>
              <el-select v-model="statusValue" placeholder="请选择" clearable style="margin-right: 20px;"
                         @change="handleStatusChange">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <el-date-picker
                v-model="invoicedDataRange"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :shortcuts="shortcuts"
                @change="handleInvoicedChange"
              >
              </el-date-picker>
            </div>
            <div class="il-list">
              <el-table :data="invoicedData" border :header-cell-style="headerColor">
                <el-table-column prop="orderNum" label="订单编号" width="230" align="center">
                </el-table-column>
                <el-table-column prop="titleName" label="发票抬头" align="center">
                </el-table-column>
                <el-table-column prop="billType" label="发票类型" width="80" align="center">
                  <template #default="scope">
                    <p v-if="scope.row.billType == '00160001'">普通发票</p>
                    <p v-if="scope.row.billType == '00160002'">专用发票</p>
                  </template>
                </el-table-column>
                <el-table-column prop="payablePrice" label="开票金额" width="80" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="申请时间" width="100" align="center">
                </el-table-column>
                <el-table-column prop="billStatus" label="开票状态" width="80" align="center">
                  <template #default="scope">
                    <p v-if="scope.row.billStatus == '0'">未申请</p>
                    <p v-if="scope.row.billStatus == '1'">开票中</p>
                    <p v-if="scope.row.billStatus == '2' && scope.row.mailStatus == '0'">已开票</p>
                    <p v-if="scope.row.billStatus == '2' && scope.row.mailStatus == '1'">已邮寄</p>
                    <p v-if="scope.row.billStatus == '3'">开票失败</p>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="160" align="center">
                  <template #default="scope">
                    <span class="btn-hover-foucs" style="margin: auto 5px;"
                             v-if="scope.row.billType == '00160002' && scope.row.billStatus == '2'"
                             @click="handlerViewExpress(scope.$index, scope.row)">查看
                    </span>
                    <span style="margin: auto 5px;" class="btn-hover-foucs" @click="handleOpen(scope.row.scanBillPic)" target="_blank"
                             v-if="scope.row.billType == '00160001' && scope.row.billStatus == '2'">查看发票
                    </span>
                    <span style="margin: auto 5px;" class="btn-hover-foucs"
                             v-if="scope.row.billType == '00160001' && scope.row.billStatus == '2'"
                             @click="handlerDownloadIvoice(scope.$index, scope.row)">下载发票
                    </span>
                    <span v-if="scope.row.billStatus == '1'">-</span>
                  </template>
                </el-table-column>
                <template #empty style="width: 100%;">
                  <el-empty :image-size="200"></el-empty>
                </template>
              </el-table>
            </div>
            <div class="il-page">
              <el-pagination
                :hide-on-single-page="true"
                :pager-count="invoicedPage.pagerCount"
                background
                layout="prev, pager, next"
                @current-change="invoicedCurrentChange"
                :total="invoicedPage.total">
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
  <el-dialog
    title="邮寄信息"
    v-model="isExpressShow"
    width="30%"
    append-to-body="true"
    :before-close="handleClose">
    <div style="padding: 0 50px;">
      <span>快递单号：</span>
      {{ currentInvoiced.mailNum }}
      <br>
      <span>快递公司：</span>
      {{ currentInvoiced.mailCompany }}
    </div>
    <template #footer>
      <div style="display: flex; justify-content: center;">
        <el-button type="primary" @click="isExpressShow=false" style="width:100px">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'
import { getOrdersByUserId } from '@/api/user.js'
import axios from 'axios'

export default {
  name: 'invoice-list',
  data () {
    return {
      invoicingData: null,
      invoicingPage: {
        pagerCount: 7,
        total: 0,
        currentPage: 1
      },
      invoicedData: null,
      invoicedPage: {
        pagerCount: 7,
        total: 0,
        currentPage: 1
      },
      shortcuts: [{
        text: '最近一周',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        })()
      }, {
        text: '最近一个月',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        })()
      }, {
        text: '最近三个月',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        })()
      }],
      invoicingDataRange: null,
      invoicedDataRange: null,
      activeName: 'invoicing',
      statusOptions: [{
        value: '1',
        label: '已申请'
      }, {
        value: '2',
        label: '已开票'
      }, {
        value: '3',
        label: '已邮寄'
      }],
      statusValue: '',
      isExpressShow: false,
      currentInvoiced: null
    }
  },
  mounted () {
    this.invoicingLoadData()
    this.invoicedLoadData()
  },
  methods: {
    headerColor: function ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    // 翻页时触发
    invoicingCurrentChange (currentPage) {
      this.invoicingPage.currentPage = currentPage
      this.invoicingLoadData()
    },
    // 翻页时触发
    invoicedCurrentChange (currentPage) {
      this.invoicedPage.currentPage = currentPage
      this.invoicedLoadData()
    },
    invoicingLoadData () {
      const beginDate = this.invoicingDataRange === null ? '' : this.invoicingDataRange[0].toLocaleDateString()
      const endDate = this.invoicingDataRange === null ? '' : this.invoicingDataRange[1].toLocaleDateString()
      console.log(beginDate + '|' + endDate)
      const params = {
        userId: JSON.parse(this.$store.getters.userInfo).userId,
        startTime: beginDate === '' ? null : beginDate,
        endTime: endDate === '' ? null : endDate,
        billStatusArr: '0',
        pageNum: this.invoicingPage.currentPage,
        pageSize: this.invoicingPage.pagerCount
      }
      getOrdersByUserId(params).then(result => {
        if (result.code === 0) {
          console.log(result.data)
          this.invoicingPage.total = result.data.total
          this.invoicingData = result.data.list
          console.log(this.invoicingData)
        } else {
          ElMessage.error({
            message: result.msg,
            offset: 60
          })
        }
      }).catch(error => {
        ElMessage.error({
          message: error,
          offset: 60
        })
      })
    },
    invoicedLoadData () {
      let billStatusArr = '1,2'
      let mailStatus = ''
      if (this.statusValue === '-1') {
        mailStatus = ''
        billStatusArr = '1,2'
      } else if (this.statusValue === '1') {
        mailStatus = ''
        billStatusArr = '1'
      } else if (this.statusValue === '2') {
        mailStatus = ''
        billStatusArr = '2'
      } else if (this.statusValue === '3') {
        mailStatus = '1'
        billStatusArr = ''
      } else {
        mailStatus = ''
        billStatusArr = '1,2'
      }
      const beginDate = this.invoicedDataRange === null ? '' : this.invoicedDataRange[0].toLocaleDateString()
      const endDate = this.invoicedDataRange === null ? '' : this.invoicedDataRange[1].toLocaleDateString()
      console.log(beginDate + '|' + endDate)
      const params = {
        userId: JSON.parse(this.$store.getters.userInfo).userId,
        startTime: beginDate === '' ? null : beginDate,
        endTime: endDate === '' ? null : endDate,
        billStatusArr: billStatusArr === '' ? null : billStatusArr,
        mailStatus: mailStatus === '' ? null : mailStatus,
        pageNum: this.invoicedPage.currentPage,
        pageSize: this.invoicedPage.pagerCount
      }
      getOrdersByUserId(params).then(result => {
        if (result.code === 0) {
          console.log(result.data)
          this.invoicedPage.total = result.data.total
          this.invoicedData = result.data.list
          console.log(this.invoicedData)
        } else {
          ElMessage.error({
            message: result.msg,
            offset: 60
          })
        }
      }).catch(error => {
        ElMessage.error({
          message: error,
          offset: 60
        })
      })
    },
    handlerIvoice (index, row) {
      this.$router.push({ path: '/invoice', query: { blOrderId: row.blOrderId } })
    },
    handleInvoicingChange () {
      this.invoicingLoadData()
    },
    handleStatusChange () {
      this.invoicedLoadData()
    },
    handleInvoicedChange () {
      this.invoicedLoadData()
    },
    handlerViewExpress (index, row) {
      console.log(row)
      this.currentInvoiced = row
      this.isExpressShow = true
    },
    handlerViewInvoice (index, row) {
      console.log(index)
      console.log(row)
    },
    handlerDownloadIvoice (index, row) {
      console.log(index)
      console.log(row)
      this.invoiceDownload(row.scanBillPic)
    },
    handleOpen (e) {
      window.open(e, '_blank')
    },
    async invoiceDownload (downloadUrl) {
      const res = await axios.get(downloadUrl, { responseType: 'blob' })
      const content = res.data
      const blob = new Blob([content], { type: 'blob' })
      const fileName = 'invoice.pdf'
      // for (const item of res.headers['content-disposition'].split(';')) {
      //   if (item.indexOf('filename') !== -1) {
      //     fileName = item.split('=')[1]
      //   }
      // }
      // 非IE下载
      const elink = document.createElement('a')
      elink.download = fileName
      elink.style.display = 'none'
      elink.href = URL.createObjectURL(blob)
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href) // 释放URL 对象
      document.body.removeChild(elink)
    }
  }
}
</script>

<style scoped>
/deep/ .el-tabs__item {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  width: 120px;
}

.il-page {
  display: flex;
  justify-content: flex-end;
  margin: 20px auto;
}

.il-tools {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.btn-hover-foucs {
  color: #5D6FE9;
  cursor: pointer;
  text-decoration: none;
}
.btn-hover-foucs:hover {
  font-weight: bold;
}
/deep/ .el-tabs__item{
  height: 55px;
  line-height: 55px;
}
</style>
